import React, { useEffect, useState } from "react";
// react plugin for creating charts

import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import Pie3 from "./Pie3";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from '@material-ui/core';
import { Box } from '@mui/material';
import { Grid } from "@material-ui/core";
import Typography from '@mui/material/Typography';
import DataLables from "chartjs-plugin-datalabels";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useAuth } from "util/use-auth";
import { Roles, Risks } from "../../models";
import { DataStore, Predicates } from "@aws-amplify/datastore";
import { getImpactCostData } from "util/RiskData";
import { getIndicativeData } from "util/RiskData";
import {
  Checkbox,
  FormControl,
  FormGroup,
  FormLabel,
  FormControlLabel,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { RiskType as RiskData } from "util/RiskData";
import { RiskType } from "components/d3-bubble-radar";
import { Cookies } from "react-cookie";
import { useRecoilState } from "recoil";
import { subCategoryState } from "../../store";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  DataLables
);

const GreenCheckbox = withStyles({
  root: {
    color: "#6bc3ce",
    "&$checked": {
      color: "#6bc3ce",
    },
    padding: "2px 9px",
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const customStyles = {
  ...styles,
  main: {
    width: "100%",
    height: "100%",
  },
  filterBox: {
    marginTop: 10,
    marginRight: 10,
    marginLeft: 10
  }
};

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: "Indicative Live Exposure (£000s)",
    },
    datalabels: {
      display: true,
      color: "black",
      anchor: "end",
      align: "end",
      offset: 2,
      formatter: (value) => {
        return "£" + value?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
      },
    },
  },
  layout: {
    padding: {
      left: 20,
      right: 60,
    },
  },
};

const subCategories = {
  "Technology": [
    "Infrastructure Capacity",
    "Aging Equipment",
    "SPOF / Resilience",
    "Aging Apps / OS",
    "Monitoring",
    "Environmental",
    "Disaster Recovery"
  ],
  "Process": [
    "Process & Standards",
    "Key Person Dependency",
    "People Capacity",
    "Project Deployment",
    "Supplier Management"
  ],
  "Data": [
    "Logical Access",
    "Physical Access",
    "Data",
    "Vulnerabilities"
  ],
  "Asset": [
    "Licensing",
    "Loss of IT asset"
  ]
}

const useStyles = makeStyles(customStyles);

export default function ScoreBoard() {
  const classes = useStyles();
  const [initData, setInitData] = useState([]);
  const [data, setData] = useState([]);
  const [type, setType] = useState({
    Technology: true,
    Process: true,
    Data: true,
    Asset: true,
  });

  const [subCategory, setSubCategory] = useRecoilState(subCategoryState);
  const [subCategoryStatus, setSubCategoryStatus] = useState({});
  const [riskType, setRiskType] = useState(Object.keys(subCategories));
  const [impact, setImpact] = useState(['1', '2', '3', '4', '5']);
  const getSubOptions = (risks) => {
    let temp = [];
    risks.map(r => {
      temp = [...temp, ...subCategories[r]]
      return r;
    })
    return [...temp];
  }
  const [subs, setSubs] = useState(getSubOptions(riskType));
  const { authUser } = useAuth();
  const companyId = new Cookies().get("companyId");

  // const getSubCategories = () => {
  //   const subItems = {};

  //   Object.keys(type).forEach((key) => {
  //     Array.isArray(RiskData[key]) &&
  //       RiskData[key].forEach((item) => {
  //         subItems[item] = true;
  //       });
  //   });

  //   setSubCategory(subItems);
  // };

  const getData = async () => {
    let records = [];
    if (authUser.role === Roles.SUPER_ADMIN) {
      records = await DataStore.query(Risks, Predicates.ALL);
    } else {
      records = await DataStore.query(Risks, (c) =>
        c.companiesID("eq", companyId)
      );
    }

    setInitData(
      records.map((element) => {
        const item = { ...element };
        item.total = item.total || item.impact * item.probability;
        item.impactCost = item.impactCost || getImpactCostData(item);
        item.indicativeLiveExposure =
          item.indicativeLiveExposure || getIndicativeData(item);

        return {
          ...item,
          type: item.riskType,
        };
      })
    );
  };

  const checkStatus = () => {
    const subItems = {};

    Object.keys(type).forEach((key) => {
      Array.isArray(RiskData[key]) &&
        RiskData[key].forEach((item) => {
          subItems[item] = type[key];
        });
    });

    setSubCategoryStatus(subItems);
  };

  // useEffect(() => {
  //   getSubCategories();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  useEffect(() => {
    checkStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  useEffect(() => {
    filterCharts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initData]);

  useEffect(() => {
    filterCharts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [impact,subs,riskType]);

  const getRiskGroupData = () => {
    const jsonData = new Map();

    data.forEach((item) => {
      if (!jsonData.has(item.type)) jsonData.set(item.type, 0);
      jsonData.set(item.type, jsonData.get(item.type) + 1);
    });

    return [
      { 
        label: "Asset",
        value: jsonData.get("Asset") || 0, 
        color: "#8d8d8c" },
      {
        label: "Data & Access",
        value: jsonData.get("Data") || 0,
        color: "#006fb5",
      },
      {
        label: "People & Process",
        value: jsonData.get("Process") || 0,
        color: "#ef0036",
      },
      {
        label: "Technology",
        value: jsonData.get("Technology") || 0,
        color: "#4caeb9",
      },
    ];
  };

  const colorData = [
    { "Infrastructure Capacity": "#006eb4" },
    { "Aging Equipment": "#8a8a89" },
    { "SPOF / Resilience": "#3f3f44" },
    { "Aging Apps / OS": "#e20033" },
    { Monitoring: "#cacaca" },
    { Environmental: "#54bdc9" },
    { "Disaster Recovery": "#346caf" },
    { "Process & Standards": "#cacaca" },
    { "Key Person Dependency": "#52b9c5" },
    { "People Capacity": "#dc0031" },
    { "Project Deployment": "#0071b8" },
    { "Supplier Management": "#80807f" },
    { "Logical Access": "#006cb1" },
    { "Physical Access": "#de0032" },
    { Data: "#868685" },
    { Vulnerabilities: "#55c0cc" },
    { Licensing: "#346caf" },
    { "Loss of IT asset": "#a4302d" },
  ];

  const getBarChartData = () => {
    const labels = ["Asset", "Data & Access", "People & Access", "Technology"];
    const types = ["Asset", "Data", "Process", "Technology"];

    const jsonData = new Map();
    data.forEach((item) => {
      if (!jsonData.has(item.type)) jsonData.set(item.type, 0);
      jsonData.set(
        item.type,
        jsonData.get(item.type) + (item.indicativeLiveExposure || 0)
      );
    });

    return {
      labels,
      datasets: [
        {
          data: types.map((key) => jsonData.get(key) || 0),
          backgroundColor: ["#8d8d8c", "#006fb5", "#ef0036", "#4caeb9"],
        },
      ],
    };
  };

  const filterCharts = () => {
    //Risk Type Filter
    let riskTypeData = [];
    if (riskType.includes('All')) {
      riskTypeData = initData;
    } else {
      riskTypeData = initData.filter(d => {
        console.log(riskType.includes(d.riskType), riskType, d.type, d)
        return riskType.includes(d.type)
      });
    }
    //SubCategory Filter
    let subData = [];
    if (subs.includes('All')) {
      subData = riskTypeData;
    } else {
      subData = riskTypeData.filter(d => subs.includes(d.subCategory))
    }
    //Impact Filter
    let impactData = [];
    if (impact.includes('All')) {
      impactData = subData;
    } else {
      impactData = subData.filter(d => impact.includes(d.impact.toString()));
    }
    //Adding Index
    const indexedData = impactData.map(d => {
      const index = subCategories[d.type].indexOf(d.subCategory) + 1;
      return { subCategoryIndex: index, ...d };
    })
    setData(indexedData);
    console.log(indexedData)
  }

  const cloneObject = (obj) => {
    return JSON.parse(JSON.stringify(obj));
  };

  useEffect(() => {
    filterCharts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subCategory, subCategoryStatus, initData]);

  const getDataAccessRisks = (type) => {
    const jsonData = new Map();

    data
      .filter((item) => item.type === type)
      .forEach((item) => {
        if (!jsonData.has(item.subCategory)) jsonData.set(item.subCategory, 0);
        jsonData.set(item.subCategory, jsonData.get(item.subCategory) + 1);
      });

    return Array.from(jsonData.keys()).map((key) => {
      return {
        label: key,
        value: jsonData.get(key),
        color: colorData.find((item) => item[key])[key],
      };
    });
  };

  const dataGroupData = getRiskGroupData();

  return (
    <div className={classes.main}>
      <Grid container spacing={5} style={{ paddingBottom: 20 }}>
        <Grid item xs={12} md={6}>
          <Pie3
            width={500}
            height={300}
            responsive={true}
            title={"Open Risk by Risk Group"}
            data={dataGroupData}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Bar
            options={options}
            data={getBarChartData()}
            height={120}
            width={null}
            style={{ background: "white" }}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={3}>
          <Pie3
            height={300}
            rx={170}
            title={"Data & Access Risks"}
            legendTextSize={9}
            legendPadding={2}
            data={getDataAccessRisks("Data")}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Pie3
            height={300}
            rx={170}
            legendTextSize={8}
            legendPadding={2}
            title={"Asset"}
            data={getDataAccessRisks("Asset")}
          />
        </Grid>
        <Grid Item xs={12} md={3}>
          <Pie3
            height={300}
            rx={170}
            legendTextSize={8}
            legendPadding={2}
            title={"People & Process"}
            data={getDataAccessRisks("Process")}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Pie3
            height={300}
            rx={170}
            legendTextSize={8}
            legendPadding={2}
            title={"Technology"}
            data={getDataAccessRisks("Technology")}
          />
        </Grid>
      </Grid>
      <Grid container>
      <Grid item md={3} sm={12} xs={12} style={{ backgroundColor: 'white' }}>
          <Box display="flex" flexDirection="column" className={classes.filterBox}>
            <Typography align="center" variant="h3">Filter</Typography>
            <Autocomplete
              multiple
              className={classes.select}
              options={
                Object.keys(subCategories)
              }
              defaultValue={[]}
              value={riskType}
              // getOptionDisabled={(option) => {
              //   if (riskType.some(sub => sub == "All")) return true;
              // }}
              onChange={(e, newValue) => { setRiskType(newValue); setSubs(getSubOptions(newValue)) }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  label="Risk Type"
                />
              )}
            />
            <Autocomplete
              multiple
              className={classes.select}
              options={getSubOptions(Object.keys(subCategories))}
              defaultValue={['All']}
              value={subs}
              getOptionDisabled={(option) => {
                if (subs.some(sub => sub == "All")) return true;
              }}
              onChange={(e, newValue) => setSubs(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  label="SubCategory"
                />
              )}
            />
            <Autocomplete
              multiple
              className={classes.select}
              options={[
                '1', '2', '3', '4', '5'
              ]}
              defaultValue={[]}
              value={impact}
              getOptionDisabled={(option) => {
                if (impact.some(sub => sub == "All")) return true;
              }}
              onChange={(e, newValue) => setImpact(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  label="Impact"
                />
              )}
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
