/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react';
// mui components
import { Grid } from '@mui/material';
// core components
import StatusCard from './DashboardCard/StatusCard';
// Time
import getTime from './DashboardUtils/GetTime';

const DashboardLayout = ({ numberOfRisks, indicativeExposure, actionPlans, actionsDue, newRisks, openRisks, closedRisks }) => {
  const lastUpdated = getTime();
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        spacing={5}
      >
        <Grid item xs={12} sm={12} md={4} zeroMinWidth>
          <StatusCard
            Title={'Number of new risks'}
            bottomText={newRisks}
            iconIndex={0}
            iconColour={newRisks > 20 ? '#e31c4b' : newRisks < 10 ? '#01a778' : '#f6a118'}
            lastUpdated={lastUpdated}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} zeroMinWidth>
          <StatusCard
            Title={'Number of open risks'}
            bottomText={openRisks}
            iconIndex={1}
            iconColour={openRisks > 20 ? '#e31c4b' : openRisks < 10 ? '#01a778' : '#f6a118'}
            lastUpdated={lastUpdated}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} zeroMinWidth>
          <StatusCard
            Title={'Number of closed risks'}
            bottomText={closedRisks}
            iconIndex={2}
            iconColour={closedRisks > 20 ? '#01a778' : closedRisks < 10 ? '#e31c4b' : '#f6a118'}
            lastUpdated={lastUpdated}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} zeroMinWidth>
          <StatusCard
            Title={'Number of Risks'}
            bottomText={numberOfRisks}
            iconIndex={3}
            iconColour={numberOfRisks >= 100 ? '#e31c4b' : numberOfRisks < 30 ? '#01a778' : '#f6a118'}
            lastUpdated={lastUpdated}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} zeroMinWidth>
          <StatusCard
            Title={'Total Indicative Live Exposure'}
            bottomText={`£${indicativeExposure}`}
            iconIndex={4}
            iconColour={indicativeExposure >= 10000 ? '#e31c4b' : indicativeExposure < 5000 ? '#01a778' : '#f6a118'}
            lastUpdated={lastUpdated}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} zeroMinWidth>
          <StatusCard
            Title={'Action plans in place'}
            bottomText={actionPlans}
            iconIndex={5}
            iconColour={actionPlans >= 50 ? '#01a778' : actionPlans >= 25 ? '#f6a118' : '#e31c4b'}
            lastUpdated={lastUpdated}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} zeroMinWidth>
          <StatusCard
            Title={'Actions due this week'}
            bottomText={actionsDue}
            iconIndex={6}
            iconColour={actionsDue >= 20 ? '#e31c4b' : actionsDue < 10 ? '#01a778' : '#f6a118'}
            lastUpdated={lastUpdated}
          />
        </Grid>
      </Grid>
    </>
  );
};
export default DashboardLayout;
