/* eslint-disable react/prop-types */
import * as React from 'react';

// Icons for each
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import ListAltIcon from '@mui/icons-material/ListAlt';
import SavingsIcon from '@mui/icons-material/Savings';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import ErrorIcon from '@mui/icons-material/Error';
import HelpIcon from '@mui/icons-material/Help';

function StatusCardIcon({ iconIndex }) {
  switch (iconIndex) {
    case 0:
      return (
        <NewReleasesIcon
          sx={{
            flexGrow: 1, p: 2, fontSize: 80, color: '#FFFFFF',
          }}
        />
      );
    case 1:
      return (
        <LockOpenIcon
          sx={{
            flexGrow: 1, p: 2, fontSize: 80, color: '#FFFFFF',
          }}
        />
      );
    case 2:
      return (
        <LockIcon
          sx={{
            flexGrow: 1, p: 2, fontSize: 80, color: '#FFFFFF',
          }}
        />
      );
    case 3:
      return (
        <ListAltIcon
          sx={{
            flexGrow: 1, p: 2, fontSize: 80, color: '#FFFFFF',
          }}
        />
      );
    case 4:
      return (
        <SavingsIcon
          sx={{
            flexGrow: 1, p: 2, fontSize: 80, color: '#FFFFFF',
          }}
        />
      );
    case 5:
      return (
        <FactCheckIcon
          sx={{
            flexGrow: 1, p: 2, fontSize: 80, color: '#FFFFFF',
          }}
        />
      );
    case 6:
      return (
        <ErrorIcon
          sx={{
            flexGrow: 1, p: 2, fontSize: 80, color: '#FFFFFF',
          }}
        />
      );
    

    default:
      return (
        <HelpIcon
          sx={{
            flexGrow: 1, p: 2, fontSize: 80, color: '#FFFFFF',
          }}
        />
      );
  }
}

export default StatusCardIcon;
