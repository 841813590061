/* eslint-disable */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import BubbleRadar, { RiskType } from "components/d3-bubble-radar";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from '@material-ui/core';
import { Box } from '@mui/material';
import { Grid } from "@material-ui/core";
import Typography from '@mui/material/Typography';
import { useAuth } from "util/use-auth";
import { Roles, Risks } from "../../models";
import { DataStore, Predicates } from "@aws-amplify/datastore";
import { Cookies } from "react-cookie";

const customStyles = {
  ...styles,
  main: {
    background: "#292929",
    width: "100%",
    height: 'auto',
  },
  title: {
    color: "white",
    fontWeight: 600,
  },
  formControl: {
    margin: 1,
  },
  legend: {
    color: "#fff !important",
  },
  select: {
    backgroundColor: 'white',
    marginBottom: 10
  },
  filterBox: {
    marginTop: 10,
    marginRight: 10,
    marginLeft: 10
  }
};

const subCategories = {
  "Technology": [
    "Infrastructure Capacity",
    "Aging Equipment",
    "SPOF / Resilience",
    "Aging Apps / OS",
    "Monitoring",
    "Environmental",
    "Disaster Recovery"
  ],
  "Process": [
    "Process & Standards",
    "Key Person Dependency",
    "People Capacity",
    "Project Deployment",
    "Supplier Management"
  ],
  "Data": [
    "Logical Access",
    "Physical Access",
    "Data",
    "Vulnerabilities"
  ],
  "Asset": [
    "Licensing",
    "Loss of IT asset"
  ]
}


const useStyles = makeStyles(customStyles);

export default function RadarEntries() {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [initData, setInitData] = useState([]);
  const { authUser } = useAuth();
  const [width, setWidth] = useState(window.innerWidth);
  const companyId = new Cookies().get("companyId");
  const filter = new Cookies().get('filter');
  const [riskType, setRiskType] = useState(Object.keys(subCategories));
  const [impact, setImpact] = useState(['1', '2', '3', '4', '5']);
  const getSubOptions = (risks) => {
    let temp = [];
    risks.map(r => {
      temp = [...temp, ...subCategories[r]]
      return r;
    })
    return [...temp];
  }
  const [subs, setSubs] = useState(getSubOptions(riskType));
  const filterData = () => {
    const filterData = cloneObject(initData)

    if (filterData.length > 0 && filter) {
      let result = getTypeFilter(filter.riskType, filterData);
      result = getSubCatFilter(filter.subCategory, result);

      const resultList = result.map((item) => {
        const index = RiskType[item.type].indexOf(item.subCategory) + 1;
        return { impact: item.impact, probability: item.probability, subCategory: item.subCategory, total: item.total, type: item.type, subCategoryIndex: index };
      });
      console.log(resultList)
      setData(resultList);
    }
    else {
      const resultList = filterData.map((item) => {
        const index = RiskType[item.type].indexOf(item.subCategory) + 1;
        return { impact: item.impact, probability: item.probability, subCategory: item.subCategory, total: item.total, type: item.type, subCategoryIndex: index };
      });
      console.log(resultList)
      setData(resultList);
    }
  };

  const getTypeFilter = (obj, data) => {
    if (!obj) return data;

    let result = [];
    let conditions = [];
    if (obj.operator) {
      for (let [key, value] of Object.entries(obj)) {
        if (key !== "filterType" && key !== "operator") {
          conditions.push({ key, value })
        }
      }

      const type_1 = conditions[0].value.type;
      const type_2 = conditions[1].value.type;

      const filter_1 = conditions[0].value.filter;
      const filter_2 = conditions[1].value.filter;

      if (obj.operator === "OR") {
        result = data.filter(s => (type_1 === "contains" ? s.type.toLowerCase().includes(filter_1.toLowerCase()) :
          (type_1 === "notContains" ? !s.type.toLowerCase().includes(filter_1.toLowerCase()) :
            (type_1 === "equals" ? s.type.toLowerCase() === filter_1.toLowerCase() :
              (type_1 === "notEqual" ? s.type.toLowerCase() === filter_1.toLowerCase() :
                (type_1 === "startsWith" ? s.type.toLowerCase().startsWith(filter_1.toLowerCase()) :
                  (s.type.toLowerCase().endsWith(filter_1.toLowerCase()))))))) ||
          (type_2 === "contains" ? s.type.toLowerCase().includes(filter_2.toLowerCase()) :
            (type_2 === "notContains" ? !s.type.toLowerCase().includes(filter_2.toLowerCase()) :
              (type_2 === "equals" ? s.type.toLowerCase() === filter_2.toLowerCase() :
                (type_2 === "notEqual" ? s.type.toLowerCase() === filter_2.toLowerCase() :
                  (type_2 === "startsWith" ? s.type.toLowerCase().startsWith(filter_2.toLowerCase()) :
                    (s.type.toLowerCase().endsWith(filter_2.toLowerCase()))))))))

      }
      else {
        result = data.filter(s => (type_1 === "contains" ? s.type.toLowerCase().includes(filter_1.toLowerCase()) :
          (type_1 === "notContains" ? !s.type.toLowerCase().includes(filter_1.toLowerCase()) :
            (type_1 === "equals" ? s.type.toLowerCase() === filter_1.toLowerCase() :
              (type_1 === "notEqual" ? s.type.toLowerCase() === filter_1.toLowerCase() :
                (type_1 === "startsWith" ? s.type.toLowerCase().startsWith(filter_1.toLowerCase()) :
                  (s.type.toLowerCase().endsWith(filter_1.toLowerCase()))))))) &&
          (type_2 === "contains" ? s.type.toLowerCase().includes(filter_2.toLowerCase()) :
            (type_2 === "notContains" ? !s.type.toLowerCase().includes(filter_2.toLowerCase()) :
              (type_2 === "equals" ? s.type.toLowerCase() === filter_2.toLowerCase() :
                (type_2 === "notEqual" ? s.type.toLowerCase() === filter_2.toLowerCase() :
                  (type_2 === "startsWith" ? s.type.toLowerCase().startsWith(filter_2.toLowerCase()) :
                    (s.type.toLowerCase().endsWith(filter_2.toLowerCase()))))))))
      }
    }
    else {
      const _type = obj.type;
      const filter = obj.filter;

      result = data.filter(s => (_type === "contains" ? s.type.toLowerCase().includes(filter.toLowerCase()) :
        (_type === "notContains" ? !s.type.toLowerCase().includes(filter.toLowerCase()) :
          (_type === "equals" ? s.type.toLowerCase() === filter.toLowerCase() :
            (_type === "notEqual" ? s.type.toLowerCase() === filter.toLowerCase() :
              (_type === "startsWith" ? s.type.toLowerCase().startsWith(filter.toLowerCase()) :
                (s.type.toLowerCase().endsWith(filter.toLowerCase()))))))))
    }
    return result;
  }


  const getSubCatFilter = (obj, data) => {
    if (!obj) return data;

    let result = [];
    let conditions = [];
    if (obj.operator) {
      for (let [key, value] of Object.entries(obj)) {
        if (key !== "filterType" && key !== "operator") {
          conditions.push({ key, value })
        }
      }

      const type_1 = conditions[0].value.type;
      const type_2 = conditions[1].value.type;

      const filter_1 = conditions[0].value.filter;
      const filter_2 = conditions[1].value.filter;

      if (obj.operator === "OR") {
        result = data.filter(s => (type_1 === "contains" ? s.subCategory.toLowerCase().includes(filter_1.toLowerCase()) :
          (type_1 === "notContains" ? !s.subCategory.toLowerCase().includes(filter_1.toLowerCase()) :
            (type_1 === "equals" ? s.subCategory.toLowerCase() === filter_1.toLowerCase() :
              (type_1 === "notEqual" ? s.subCategory.toLowerCase() === filter_1.toLowerCase() :
                (type_1 === "startsWith" ? s.subCategory.toLowerCase().startsWith(filter_1.toLowerCase()) :
                  (s.subCategory.toLowerCase().endsWith(filter_1.toLowerCase()))))))) ||
          (type_2 === "contains" ? s.subCategory.toLowerCase().includes(filter_2) :
            (type_2 === "notContains" ? !s.subCategory.toLowerCase().includes(filter_2) :
              (type_2 === "equals" ? s.subCategory.toLowerCase() === filter_2.toLowerCase() :
                (type_2 === "notEqual" ? s.subCategory.toLowerCase() === filter_2.toLowerCase() :
                  (type_2 === "startsWith" ? s.subCategory.toLowerCase().startsWith(filter_2.toLowerCase()) :
                    (s.subCategory.toLowerCase().endsWith(filter_2.toLowerCase()))))))))

      }
      else {
        result = data.filter(s => (type_1 === "contains" ? s.subCategory.toLowerCase().includes(filter_1.toLowerCase()) :
          (type_1 === "notContains" ? !s.subCategory.toLowerCase().includes(filter_1.toLowerCase()) :
            (type_1 === "equals" ? s.subCategory.toLowerCase() === filter_1.toLowerCase() :
              (type_1 === "notEqual" ? s.subCategory.toLowerCase() === filter_1.toLowerCase() :
                (type_1 === "startsWith" ? s.subCategory.toLowerCase().startsWith(filter_1.toLowerCase()) :
                  (s.type.toLowerCase().endsWith(filter_1.toLowerCase()))))))) &&
          (type_2 === "contains" ? s.subCategory.toLowerCase().includes(filter_2) :
            (type_2 === "notContains" ? !s.subCategory.toLowerCase().includes(filter_2) :
              (type_2 === "equals" ? s.subCategory.toLowerCase() === filter_2.toLowerCase() :
                (type_2 === "notEqual" ? s.subCategory.toLowerCase() === filter_2.toLowerCase() :
                  (type_2 === "startsWith" ? s.subCategory.toLowerCase().startsWith(filter_2.toLowerCase()) :
                    (s.subCategory.toLowerCase().endsWith(filter_2.toLowerCase()))))))))
      }
    }
    else {
      const _type = obj.type;
      const filter = obj.filter;

      result = data.filter(s => (_type === "contains" ? s.subCategory.toLowerCase().includes(filter.toLowerCase()) :
        (_type === "notContains" ? !s.subCategory.toLowerCase().includes(filter.toLowerCase()) :
          (_type === "equals" ? s.subCategory.toLowerCase() === filter.toLowerCase() :
            (_type === "notEqual" ? s.subCategory.toLowerCase() === filter.toLowerCase() :
              (_type === "startsWith" ? s.subCategory.toLowerCase().startsWith(filter.toLowerCase()) :
                (s.subCategory.toLowerCase().endsWith(filter.toLowerCase()))))))))
    }
    return result;
  }

  const cloneObject = (obj) => {
    return JSON.parse(JSON.stringify(obj));
  };

  const getData = async () => {
    let records: Risks[] = [];

    if (authUser.role === Roles.SUPER_ADMIN) {
      records = await DataStore.query(Risks, Predicates.ALL);
    } else {
      records = await DataStore.query(Risks, (c) => c.companiesID("eq", companyId));
    }
    console.log(records);
    setInitData(
      records.map((element) => {
        const item: Risks = { ...element };
        item.total = item.total || item.impact * item.probability;
        item.indicativeLiveExposure = calcLiveExposure( item )

        return {
          id:item.riskId,
          title:item.title,
          liveExposure:item.indicativeLiveExposure,
          type: item.riskType,
          subCategory: item.subCategory,
          impact: item.impact,
          total: item.total,
          probability: item.probability,
        };
      })
    );
  };
  const filterRadar = () => {
    //Risk Type Filter
    let riskTypeData = [];
    if (riskType.includes('All')) {
      riskTypeData = initData;
    } else {
      riskTypeData = initData.filter(d => {
        console.log(riskType.includes(d.riskType), riskType, d.type, d)
        return riskType.includes(d.type)
      });
    }
    //SubCategory Filter
    let subData = [];
    if (subs.includes('All')) {
      subData = riskTypeData;
    } else {
      subData = riskTypeData.filter(d => subs.includes(d.subCategory))
    }
    //Impact Filter
    let impactData = [];
    if (impact.includes('All')) {
      impactData = subData;
    } else {
      impactData = subData.filter(d => impact.includes(d.impact.toString()));
    }
    //Adding Index
    const indexedData = impactData.map(d => {
      const index = subCategories[d.type].indexOf(d.subCategory) + 1;
      return { subCategoryIndex: index, ...d };
    })
    setData(indexedData);
  }
    // working out live exposesure
    const calcLiveExposure = (item) => {
      return item.status === "Closed"
      ? ""
      : item.probability === 5
      ? item.impact * 3
      : item.probability === 4
      ? item.impact * 1
      : item.probability === 3
      ? item.impact * 0.5
      : item.probability === 2
      ? item.impact * 0.3
      : item.probability === 1
      ? item.impact * 0.1
      : "";
    }
  const handleWindowSizeChange = () => {
    console.log(window.innerWidth)
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  useEffect(() => {
    filterRadar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initData, riskType, subs, impact]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);
  return (
    <div className={classes.main}>
      <Grid container>
        <Grid item md={9} sm={12} xs={12}>
          <Box display="flex" justifyContent="center">
            <BubbleRadar dimensions={{width,height:width, margin: { left: 10, right: 10, top: 10, bottom: 10 }}} width={width} style={{width}} data={data} />
          </Box>
        </Grid>
        <Grid item md={3} sm={12} xs={12} style={{ backgroundColor: 'white' }}>
          <Box display="flex" flexDirection="column" className={classes.filterBox}>
            <Typography align="center" variant="h3">Filter</Typography>
            <Autocomplete
              multiple
              className={classes.select}
              options={
                Object.keys(subCategories)
              }
              defaultValue={[]}
              value={riskType}
              // getOptionDisabled={(option) => {
              //   if (riskType.some(sub => sub == "All")) return true;
              // }}
              onChange={(e, newValue) => { setRiskType(newValue); setSubs(getSubOptions(newValue)) }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  label="Risk Type"
                />
              )}
            />
            <Autocomplete
              multiple
              className={classes.select}
              options={getSubOptions(Object.keys(subCategories))}
              defaultValue={['All']}
              value={subs}
              getOptionDisabled={(option) => {
                if (subs.some(sub => sub == "All")) return true;
              }}
              onChange={(e, newValue) => setSubs(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  label="SubCategory"
                />
              )}
            />
            <Autocomplete
              multiple
              className={classes.select}
              options={[
                '1', '2', '3', '4', '5'
              ]}
              defaultValue={[]}
              value={impact}
              getOptionDisabled={(option) => {
                if (impact.some(sub => sub == "All")) return true;
              }}
              onChange={(e, newValue) => setImpact(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  label="Impact"
                />
              )}
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
