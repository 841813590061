/* eslint-disable react/prop-types */
import React from 'react';

// mui components
import {
  Card, Box, Grid, Divider, Typography,
} from '@mui/material';
// Icons
import UpdateIcon from '@mui/icons-material/Update';
import StatusCardIcon from './StatusCardIcon';

const StatusCard = ({
  Title,
  bottomText,
  iconIndex,
  iconColour,
  lastUpdated,
}) => (
  <>
    <Box>
      <Card
        sx={{
          bgcolor: `${iconColour}`,
          height: 80,
          width: 80,
          mt: -2,
          mr: -12,
          ml: 2,
          boxShadow: 8,
        }}
        style={{ float: 'left' }}
      >
        <StatusCardIcon iconIndex={iconIndex} />
      </Card>
      <Card sx={{ minHeight: 150, boxShadow: 10 }}>
        <Grid
          container
        >
          <Grid item xs={12} sx={{ textAlign: 'right' }} zeroMinWidth>
            <Typography
              sx={{
                fontWeight: 100,
                fontSize: 16,
                mt: 1,
                mr: 1,
                color: '#6f6f6f',
              }}
            >
              {Title}
            </Typography>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: 35,
                mx: 1,
              }}
            >
              {bottomText}
            </Typography>
          </Grid>
          <Grid item xs={12} zeroMinWidth>
            <Grid
              container
              sx={{
                mt: 2.5, ml: 2, mr: 2, mb: 1.25,
              }}
            >
              <Grid item xs={11} zeroMinWidth>
                <Divider sx={{ pb: 1.25 }} />
              </Grid>
              <Grid item xs={1.5} zeroMinWidth>
                <UpdateIcon sx={{ m: 1, fontSize: 20, color: '#C4C4C4' }} />
              </Grid>
              <Grid item xs={10.5} zeroMinWidth>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: 15,
                    ml: -1,
                    my: 1,
                    color: '#C4C4C4',
                  }}
                >
                  {`Updated ${lastUpdated.toString().slice(0, 24)}`}

                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Box>
  </>
);
export default StatusCard;
