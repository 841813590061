import React, { useState, useEffect } from 'react';
import DashboardLayout from './DashboardLayout';
import GetArraySum from './DashboardUtils/GetArraySum';
import { CircularProgress } from '@mui/material';
import { useAuth } from "util/use-auth";
import { Risks, Roles, Action, CustomersCompanies, Companies, Customers } from "../../models";
import { DataStore, Predicates, SortDirection } from "@aws-amplify/datastore";
import { Cookies } from "react-cookie";

const DashboardPage = () => {
  const [risks, setRisks] = useState(null); 
  const [dashboardValue, setDashboardValues] = useState(null)
  const [newRisks, setNewRisks] = useState(null);
  const [openRisks, setOpenRisks] = useState(null);
  const [closedRisks, setClosedRisks] = useState(null);
  const [numberOfRisks, setNumberOfRisks] = useState(null);
  const [indicativeExposure, setIndicativeExposure] = useState(null);
  const [actionPlans, setActionPlans] = useState(null);
  const [actionsDue, setActionsDue] = useState(null);
  const [customers, setCustomers] = useState();
  const [allCompanies, setAllCompanies] = useState([]);
  const { authUser } = useAuth();
  const companyId = new Cookies().get("companyId");

  console.log(risks)

  const getRiskData = async () => {
      let records: Risks[];
  
      if (authUser.role === Roles.SUPER_ADMIN) {
        records = await DataStore.query(Risks, Predicates.ALL, {
          sort: (s) => s.riskId(SortDirection.ASCENDING),
        });
      } else {
        records = await DataStore.query(
          Risks,
          (c) => c.companiesID("eq", companyId),
          {
            sort: (s) => s.riskId(SortDirection.ASCENDING),
          }
        );
      }
  
      setRisks(
        records.map((element: Risks) => {
          const item: Risks = { ...element };
          const action: Action = item.actions.length ? item.actions[0] : {};
          item.total = item.total || item.impact * item.probability;
          item.impactCost = item.impactCost || getImpactCostData(item);
          item.indicativeLiveExposure =
            item.indicativeLiveExposure || getIndicativeData(item);
          return {
            ...item,
            action: action.Action,
            actionDueDate: action.ActionDueDate,
          };
        })
      );
      const cookies = new Cookies();
      const filter = cookies.get('filter');
    };
  
    const getImpactCostData = (data: Risks) => {
      const impact = data.impact;
      return impact === 1
        ? 0.5
        : impact === 2
        ? 1
        : impact === 3
        ? 10
        : impact === 4
        ? 100
        : impact === 5
        ? 1000
        : false;
    };
  
    const getIndicativeData = (data: Risks) => {
      return data.status === "Closed"
        ? ""
        : data.probability === 5
        ? data.impactCost * 3
        : data.probability === 4
        ? data.impactCost * 1
        : data.probability === 3
        ? data.impactCost * 0.5
        : data.probability === 2
        ? data.impactCost * 0.3
        : data.probability === 1
        ? data.impactCost * 0.1
        : "";
    };
  
    const getCustomers = async () => {
      let records;
      if (authUser.role === Roles.SUPER_ADMIN) {
        records = await DataStore.query(Customers);
      } else if (authUser.role === Roles.COMPANY_ADMIN) {
        records = await DataStore.query(CustomersCompanies, Predicates.ALL);
        records = records
          .filter((item) => item.companies.id === companyId)
          .map((item) => item.customers);
      } else if (authUser.role !== undefined) {
        records = [await DataStore.query(Customers, authUser.id)];
      }
  
      setCustomers(
        Array.isArray(records) &&
          records.map((item: Customers) => {
            return {
              label: item.firstName + " " + item.lastName,
              value: item.id,
            };
          })
      );
    };
  
    const getCompanies = async () => {
      const records = await DataStore.query(Companies);
      setAllCompanies(
        records.map(record => {
          return {
            label: record.name,
            value: record.id
          }
        })
      )
    }
  
    useEffect(() => {
      getCustomers();
      getCompanies()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authUser]);
  
    useEffect(() => {
      if (customers) {
        getRiskData();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customers]);

  const statusType = { new: "NEW", open: "OPEN", closed: "CLOSED", tolerated: "TOLERATED" }

  useEffect(() => {
    if (risks) {
      setNewRisks(filterRisks(risks, statusType.new).length);
      setOpenRisks(filterRisks(risks, statusType.open).length);
      setClosedRisks(filterRisks(risks, statusType.closed).length);
      setNumberOfRisks(filterAllRisks(risks).length);
      setIndicativeExposure(GetArraySum(filterAllRisks(risks).map((risk) => {
        return risk['indicativeLiveExposure']
      })))
      setActionPlans(filterByActions(filterAllRisks(risks)).length);
      setActionsDue(isInThisWeek(filterAllRisks(risks)).length)
    }
  }, [risks])

  const filterRisks = (risks, status) => {
    return risks.filter((risk) => (
      risk.status === status
    ))
  }

  const filterByActions = (risks) => {
    return risks.filter((risk) => (
      risk.action.length > 0
    ))
  }

  const filterAllRisks = (risks) => {
    return risks.filter((risk) => (
      risk.status === statusType.new ||
      risk.status === statusType.open ||
      risk.status === statusType.tolerated
    ))
  }

  // Filters array of risks to only risks with action due dates this week
  const isInThisWeek = (risks) => {
    if (!risks) return [];
    const today = new Date();
    const first = today.getDate() - today.getDay();
    const last = first + 7;

    const lastSunday = new Date(today.setDate(first));
    const nextMonday = new Date(today.setDate(last));

    return risks.filter((risk) => (
      new Date(risk.actionDueDate) > lastSunday && new Date(risk.actionDueDate) < nextMonday
    ))

  };

  const loadPage = (value) => {
    if (value >= 0 && typeof value === "number") {
      return true
    }
    return false
  }
  
  // eslint-disable-next-line max-len
  if (loadPage(numberOfRisks) && loadPage(indicativeExposure) && loadPage(actionPlans) && loadPage(actionsDue) && loadPage(newRisks) && loadPage(openRisks) && loadPage(closedRisks)) {
    return (
      <DashboardLayout 
        numberOfRisks={numberOfRisks}
        indicativeExposure={indicativeExposure}
        actionPlans={actionPlans}
        actionsDue={actionsDue}
        newRisks={newRisks}
        openRisks={openRisks}
        closedRisks={closedRisks} />
    )
  }
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
    >
      <CircularProgress />
    </div>
  );
}

export default DashboardPage;
